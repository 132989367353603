@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
	$color-config: mat.get-color-config($theme);
	$background: map.get($color-config, background);
	$foreground: map.get($color-config, foreground);

	.sh-search-container,
	.sh-search-input {
		background-color: mat.get-color-from-palette($background, background);
		color: mat.get-color-from-palette($foreground, text);
	}
}

// Complete theme for the library.
@mixin theme($theme) {
	$color-config: mat.get-color-config($theme);
	@if $color-config != null {
		@include color($theme);
	}
}
