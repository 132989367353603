@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
	$config: mat.get-color-config($theme);
	$foreground: map.get($config, foreground);

	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 5px;
		background-color: mat.get-color-from-palette($foreground, secondary-text);
	}
	::-webkit-scrollbar-track {
		border-radius: 0;
	}
}
