@use 'sass:map';
@use '@angular/material' as mat;

@mixin override-theme($theme) {
	$config: mat.get-color-config($theme);
	$accent: map.get($config, accent);

	.mat-mdc-list-item,
	.mat-mdc-list-option {
		&.active,
		&[aria-selected='true'] {
			background-color: mat.get-color-from-palette($accent, 0.12);
		}
		&.active .mdc-list-item__primary-text {
			color: black !important;
		}
		&[aria-selected='true'] .mdc-list-item__primary-text {
			color: black !important;
		}
	}

	.mat-mdc-list-base {
		--mdc-list-list-item-focus-state-layer-color: transparent;
	}
}
