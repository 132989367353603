@use 'sass:map';
@use '@angular/material' as mat;

@mixin override-theme($theme) {
	$config: mat.get-color-config($theme);
	$accent: map.get($config, accent);
	$foreground: map.get($config, foreground);

	$accent-color: mat.get-color-from-palette($accent);

	.mat-mdc-slider {
		.mdc-slider__track--active_fill {
			background-color: $accent-color !important;
			border: none !important;
		}
		.mdc-slider__track--inactive {
			background-color: mat.get-color-from-palette($foreground, text) !important;
		}
		.mdc-slider__thumb-knob {
			background-color: $accent-color !important;
			border-color: $accent-color !important;
		}
		.mat-mdc-slider-visual-thumb .mat-ripple {
			display: none;
		}
	}
}

@mixin override-style() {
	.mat-mdc-slider {
		.mdc-slider__track {
			.mdc-slider__track--inactive,
			.mdc-slider__track--active {
				height: 2px !important;
				top: 1px !important;
			}
		}
	}
}
